@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Slate by Pixelarity
	pixelarity.com @pixelarity
	License: pixelarity.com/license
*/

/* Banner */

	#banner {
		> article {
			display: none;

			&:first-child {
				background-image: url('../../images/slide01.jpg');
				display: block !important;
				opacity: 1 !important;
				visibility: visible !important;
			}
		}
	}